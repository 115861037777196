<template>
    <div class="PrintPage">
        <el-page-header @back="back" content="打印预览"></el-page-header>
        <div id="printDiv">
            <div class="box1 f-c-b">
                <el-image style="width: 350px;align-self: flex-start;" :src="Info1.enterpriseLogo" fit="cover">
                    <div slot="error" class="image-slot">
                        <img width="100%" height="100%" :src="require('@/assets/logo1.png')" alt="公司logo">
                    </div>
                </el-image>
                <div class="right f-cl-b">
                    <div class="title" :style="{ 'letter-spacing': Info1.enterpriseNameLength > 10 ? '22px;' : '24px' }">{{
                        Info1.enterpriseName
                    }}</div>
                    <div class="sub-info">
                        <div>{{ templateParam == 'SA' ? '销售单' : (templateParam == 'RG' ? '退货单' : '供应商退回单') }}</div>
                        <div>
                            <span v-html="Info1.enterpriseInfo1"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="f-c-b Info3_style">
                <div class="f-fs Info3_item" v-for="(item, index) of Info3" :key="index">
                    <span class="whiteSpaceNo">{{ item.title }}</span>
                    <span class="wordBreak-all w--75">{{ item.value }}</span>
                </div>
            </div>
            <table class="table_list">
                <tr>
                    <td align="center" v-for="(item, index) of TableColumnHead" :style="{ width: item.width }" :key="index">
                        {{ item.title }}</td>
                </tr>
                <tr v-for="(item, index) of Info2.orderDetailsList" :key="index">
                    <th>{{ index + 1 }}</th>
                    <th>{{ item.customerUseName ? item.customerUseName : (item.productName ? item.productName : '无') }}</th>
                    <th>{{ item.customerUseNumber ? item.customerUseNumber : (item.productNumber ? item.productNumber : '无')
                    }}</th>
                    <th>{{ item.quantity }}</th>
                    <th>{{ item.unitPrice }}</th>
                    <th>{{ item.amount2 }}</th>
                    <th>{{ item.note }}</th>
                </tr>
                <tr>
                    <th align="center" colspan="2">合计大写金额({{ Info2.valuationCurrency }})</th>
                    <th align="center" colspan="4">{{ Info2.UppercaseMoney }}</th>
                    <th align="center" colspan="1">{{ (Info2.valuationCurrency + '' + Info2.realityAmount2) }}</th>
                </tr>
                <tr>
                    <th style="padding: 5px 0 5px 20px;text-align: left; word-break: break-all;" colspan="7"
                        v-html="Info1.enterpriseNote1"></th>
                </tr>
            </table>
            <div class="other f-c-b">
                <div class="f-cl">
                    <span>甲方(盖章)：</span>
                    <span>法定代表人授权代表(签字)：</span>
                </div>
                <div class="f-cl">
                    <span>已方(盖章)：</span>
                    <span>(客户)法定代表人授权代表(签字)：</span>
                </div>
            </div>
        </div>
        <div class="One-btn f-c-a">
            <el-button type="primary" round v-print="printObj">打印</el-button>
        </div>
    </div>
</template>

<script>
import { TableColumn3 } from '@/utils/TableColumn'

export default {
    name: 'PrintPage',
    data() {
        return {
            Info1: {},
            Info2: {},
            templateParam: '',//单据类型SA(销售单)、RG（退货单）、RS（供应商退回单）
            Info3: [],
            TableColumnHead: TableColumn3,
            printObj: {
                id: "printDiv", //打印的区域的id名
                popTitle: "", // 打印配置页上方标题
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
                extraCss: ''
            },
        }
    },
    created() {
        let { templateParam } = this.$getStorage('PrintParam');
        this.templateParam = templateParam;
        this.$CommonRequests.getEnterpriseOrderTemplate(templateParam).then((res) => {
            let { enterpriseNote, enterpriseInfo } = res.data;
            let newEnterpriseNote = enterpriseNote.replace(/\n/g, "<br/>"),
                changeEnterpriseInfo = enterpriseInfo.replace(/\n/g, "<br/>");
            res.data.enterpriseNote1 = newEnterpriseNote;
            res.data.enterpriseInfo1 = changeEnterpriseInfo
            // res.data.enterpriseInfo1 = this.insertEnter(enterpriseInfo, 50)
            res.data.enterpriseNameLength = res.data.enterpriseName.length
            this.Info1 = res.data
        })
        this.findAllOrderDetails()
    },
    methods: {
        back() {
            this.$router.go(-1);
            this.$removeStorage('PrintParam')
        },
        insertEnter(str, n) {
            var len = str.length;
            var strTemp = '';
            if (len > n) {
                strTemp = str.substring(0, n);
                str = str.substring(n, len);
                return strTemp + '<br />' + this.insertEnter(str, n);
            } else {
                return str;
            }
        },
        // 用户搜索查看全部订单列表详细信息接口
        findAllOrderDetails() {
            let that = this,
                { orderId, orderStatus } = that.$getStorage('PrintParam');
            that.$http.findAllOrderDetails({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                orderId,
                orderTypeStatus:orderStatus
            }).then((res) => {
                res.data.orderDetailsList.forEach((item) => {
                    if (item.deleteFlag == "R") {
                        item.amount2 = '已退货'
                    }
                })
                res.data.UppercaseMoney = that.number_chinese(res.data.realityAmount2)
                this.Info2 = res.data
                let { orderNumber, customerName, orderStatus, createDate, contact, createName,
                    contactPhone, customerCity, customerAddress } = res.data,
                    address = customerCity + ' ' + customerAddress,
                    orderStatusText = '';
                switch (orderStatus) {
                    case 'Ready'://未付款
                    case 'Deliver':
                    case 'Delivery':
                        orderStatusText = '未付款';
                        break;
                    case 'Completed':
                        orderStatusText = '已付款';
                        break;
                    case 'Canceled':
                        orderStatusText = '已取消';
                        break;
                }
                this.Info3 = [{
                    title: '客户名称：',
                    value: customerName
                }, {
                    title: '单据编号：',
                    value: orderNumber
                }, {
                    title: '联系人：',
                    value: contact ? contact : '暂无'
                }, {
                    title: '创建时间：',
                    value: createDate
                }, {
                    title: '联系电话：',
                    value: contactPhone
                }, {
                    title: '创建人：',
                    value: createName
                }, {
                    title: '地址：',
                    value: address
                }, {
                    title: '订单状态：',
                    value: orderStatusText
                }];
            })
        },
        number_chinese(str) {
            var num = parseFloat(str);
            var strOutput = "",
                strUnit = '仟佰拾亿仟佰拾万仟佰拾圆角分';
            num += "00";
            var intPos = num.indexOf('.');
            if (intPos >= 0) {
                num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
            }
            strUnit = strUnit.substr(strUnit.length - num.length);
            for (var i = 0; i < num.length; i++) {
                strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) + strUnit.substr(i, 1);
            }
            return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+圆/, '圆').replace(/亿零{0,3}万/, '亿').replace(/^圆/, "零圆")
        },
    },

}

</script>
<style lang='less' scoped></style>
<style  media="print">
@page {
    size: auto;
    margin: 5mm 0mm 3mm -10mm;

}

#printDiv {
    background: #fff;
    box-sizing: border-box;
    transform: scale(.95);
    font-size: 20px;
    padding: 10px 60px 0;
}

#printDiv .box1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#printDiv .right {
    /* margin-left: 60px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}


#printDiv .right .title {
    font-size: 34px;
    font-weight: bold;
    /* letter-spacing: 22px; */
    align-self: center;

}

#printDiv .right .sub-info {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

#printDiv .right .sub-info div:first-child {
    width: 25%;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 8px;
    text-align: center;
    margin-top: 50px;
}

#printDiv .right .sub-info div:last-child {
    width: 75%;
    display: flex;
    justify-content: right;
}

#printDiv .right .sub-info div:last-child span {
    line-height: 25px;
    font-size: 18px;
    max-height: 175px;
    overflow: hidden;
}

#printDiv .Info3_style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 12px;
}

#printDiv .Info3_style .Info3_item {
    width: 50%;
    margin-bottom: 7px;
}

#printDiv .other {
    margin-top: 10px;
}

#printDiv .other div {
    width: 50%;
}

#printDiv .other div span {
    margin-bottom: 5px;
}


.table_list {
    width: 100%;
    border: 1px solid #000;
}

.table_list td {
    font-weight: bold;
}

.table_list th {
    font-weight: 400;
}

.table_list th,
.table_list td {
    border-bottom: 1px solid #000;
    padding: 4px 0;
}

.table_list td:not(:last-child),
.table_list th:not(:last-child) {
    border-right: 1px solid #000;
}
</style>
